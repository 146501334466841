export default class DNNUserModel {
  constructor(props) {
    this.UserID = props?.UserID || 0;
    this.UserAccountId = props?.UserAccountId || 0;
    this.Username = props?.Username || "en-us";
    this.Culture = props?.Culture || "";
    this.FirstName = props?.FirstName || "";
    this.LastName = props?.LastName || "";
    this.Telephone = props?.Telephone || "";
    this.DisplayName = props?.DisplayName || "";
    this.AvatarFileId = props?.AvatarFileId || 0;
    this.AvatarUrl = props?.AvatarUrl
      ? process.env.REACT_APP_BACKEND_URL + props.AvatarUrl
      : "";
    this.SignatureUrl = props?.SignatureUrl || "";
    this.LogoUrl = props?.LogoUrl || "";
    this.AvatarFileName = props?.AvatarFileName || "";
    this.Roles = props?.Roles || [];
    this.IsAdmin = props?.IsAdmin || false;
    this.IsLogged = (props?.UserID || 0) != 0;
    this.IsAccountsetUp = props?.IsAccountsetUp || false;
    this.IsArtist = props?.IsArtist || false;
    this.IsGallery = props?.IsGallery || false;
    this.IsVerified = props?.IsVerified || false;
    this.UserStatus = props?.UserStatus;
    this.WebsiteUrl = props?.WebsiteUrl || "";
    this.BrandName = props?.BrandName || "";
  }
}
