import React from "react";
import { FaSpinner } from "react-icons/fa";
import "./Spinner.scss";

export default function Spinner({
  showSpinner,
  local = false,
  fixed = false,
  text = null,
}) {
  return showSpinner ? (
    <div className={`spinner ${local ? "local" : ""} ${fixed ? "fixed" : ""}`}>
      <FaSpinner className="icon-spin" />
      <br />
      <p>{text}</p>
    </div>
  ) : (
    <></>
  );
}
