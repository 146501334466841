const serviceWorkerConfig = {
  onUpdate: (registration) => {
    return registration
      .unregister()
      .then(() => {
        console.info("Service worker unregistered successfully.");
      })
      .catch((error) => {
        console.error("Error while unregistering service worker:", error);
      });
  },
  onSuccess: (registration) => {
    console.info("Service worker is in the success state.");
  },
};

export default serviceWorkerConfig;
