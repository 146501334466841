import i18n  from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./i18n/en.json";
import translationES from "./i18n/es.json";

const fallbackLng = ['en'];
const availableLanguages = ['en', 'es'];

const resources = {
    en: {
        translation: translationEN
    },
    es: {
        translation: translationES
    }
}

i18n
    .use(Backend) // load translations using http (default/public/assets/locals/en/translations)
    .use(LanguageDetector) // detect user language
    .use(initReactI18next) // pass the i18n instance to react-i18next.
    .init({
        fallbackLng, // fallback language is english.
        lng: fallbackLng, // default language selected
        resources,
        ns: ["translation"],
        defaultNS: "translation",
        detection: {
            checkWhitelist: true, // options for language detection
        },

        debug: false,

        whitelist: availableLanguages,

        interpolation: {
            escapeValue: false, // no need for react. it escapes by default
        },

        react: {
            useSuspense: false
        }
    });

export default i18n;