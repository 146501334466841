import React from "react";
import { toast } from "react-toastify";

const options = {
  timeOut: 2000,
  removeOnHover: false,
  closeOnToastrClick: false,
  showCloseButton: true,
  progressBar: false,
};

export default class Toastr {
  static confirm(question, okCallback) {
    const toastrConfirmOptions = {
      onOk: okCallback,
      component: () => (
        <div
          className="toastr-msg"
          dangerouslySetInnerHTML={{ __html: question }}
        />
      ),
    };
    // window confirm

    if (window.confirm(question)) okCallback();
  }
  static error(msg, timeout = options.timeOut, extendedMsg = null) {
    toast.error(<ErrorMessage error={msg} extended={extendedMsg} />, {
      autoClose: timeout,
      toastId: msg,
      removeOnHover: options.removeOnHover,
      closeOnToastrClick: options.closeOnToastrClick,
      showCloseButton: options.showCloseButton,
      progressBar: options.progressBar,
    });
  }

  static success(msg, timeout = options.timeOut / 2) {
    toast.success(msg, {
      autoClose: timeout,
      toastId: msg,
      removeOnHover: options.removeOnHover,
      closeOnToastrClick: options.closeOnToastrClick,
      showCloseButton: options.showCloseButton,
      progressBar: options.progressBar,
    });
  }

  static warning(msg, timeout = options.timeOut) {
    toast.warning(msg, {
      autoClose: timeout,
      toastId: msg,
      removeOnHover: options.removeOnHover,
      closeOnToastrClick: options.closeOnToastrClick,
      showCloseButton: options.showCloseButton,
      progressBar: options.progressBar,
    });
  }

  static info(msg, timeout = options.timeOut / 2) {
    toast.info(msg, {
      autoClose: timeout,
      toastId: msg,
      removeOnHover: options.removeOnHover,
      closeOnToastrClick: options.closeOnToastrClick,
      showCloseButton: options.showCloseButton,
      progressBar: options.progressBar,
    });
  }

  static responseError(response, timeout = options.timeOut) {
    let errorMessage = "";

    if (response.request) {
      errorMessage = response.request.statusText;
    } else errorMessage = response.message;

    Toastr.error(errorMessage, timeout);
  }
}

class ErrorMessage extends React.Component {
  constructor(props) {
    super(props);
  }

  remove() {}

  render() {
    let msg = this.props.error;
    if (this.props.extended) {
      msg = msg + "<br />" + this.props.extended;
    }
    return <p className="" dangerouslySetInnerHTML={{ __html: msg }} />;
  }
}
