import moment from "moment";
import esLocale from "moment/locale/es";
import enLocale from "moment/locale/en-gb";
export default class Util {
  static numberWithCommas(x, decimals = 2) {
    if (x)
      return parseFloat(x.toString())
        .toFixed(decimals)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else return x;
  }

  static nextNiceNumber(num) {
    let step = Math.pow(10, num.toString().length - 1);
    let nice = Math.pow(10, num.toString().length);
    while (nice - step > num) {
      nice = nice - step;
    }
    return nice;
  }

  static getBase64(file) {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  }

  static toDataURL(url) {
    return fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );
  }

  static async getIcon(url) {
    let icon;
    await Util.toDataURL(url).then((data) => {
      icon = "image://" + data;
    });

    return icon;
  }

  static normalizeJSONData(string) {
    if (!string) return string;
    let normalized = string.replace(/\"([^""]*)\": *True/g, '"$1": true');
    normalized = normalized.replace(/\"([^""]*)\": *False/g, '"$1": false');
    return normalized;
  }

  static orderArray(arr, prop) {
    return arr.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
  }

  static isTrue(val) {
    return val === true || val === "true" || val === "True";
  }

  static round(number, decimalPlaces) {
    let multiplier = "1";
    for (var i = 0; i < decimalPlaces; i++) {
      multiplier += "0";
    }
    const intMultiplier = parseInt(multiplier);

    if (this.undefined(number)) {
      number = 0;
    }

    return Math.round(number * intMultiplier) / intMultiplier;
  }

  static undefined(obj) {
    return obj === undefined || obj === null || obj === "";
  }

  static isDefined(obj) {
    return !this.undefined(obj);
  }

  static resizeImage(file, maxSize, quality) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          let width = img.width;
          let height = img.height;

          if (width > maxSize || height > maxSize) {
            if (width > height) {
              height *= maxSize / width;
              width = maxSize;
            } else {
              width *= maxSize / height;
              height = maxSize;
            }
          }

          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            file.type,
            quality
          );
        };

        img.onerror = (error) => {
          reject(error);
        };
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  }

  static localePrice(
    price,
    lang = localStorage.getItem("language") || "en",
    options = {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      uSeGrouping: true,
    }
  ) {
    let locale = lang === "es" ? "es" : "en-US";
    return price.toLocaleString(locale, options);
  }

  static localeDate(date) {
    var locale = localStorage.getItem("language") || "en";
    let _date = moment(date);
    return _date.locale(locale).format("D-MMM-YYYY").toLowerCase();
  }
}
