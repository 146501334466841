import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import { FaRegUser, FaCog, FaPowerOff } from "react-icons/fa";
import { HiHome } from "react-icons/hi";
import { useUser } from "../../common/context";
import images from "../../appcommon/images";
import { useTranslation } from "react-i18next";
import { logOut } from "../../common/services/login-instance";
import "./Menu.scss";

var versionInfo = require("../../version.json");

const Menu = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const handleLogout = () => {
    logOut();
  };

  const handleGoTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const getClass = (path) => {
    if (location.pathname.startsWith(path)) return "active";
    return "";
  };
  return (
    <div className="menu">
      <Link to="/" onClick={handleGoTop} className="logo">
        <img
          role={"button"}
          className="img-fluid"
          src={images.iTrustArtIcon}
          alt="iTrustArt"
        />
      </Link>
      <div className="userMenu">
        <Link to="/" onClick={handleGoTop} className={getClass("/home")}>
          <HiHome className="icon-home" />
        </Link>
        <Dropdown autoClose={true}>
          <Dropdown.Toggle
            variant=""
            className={`${getClass("/user")} avatar p-0`}
            id="user-menu"
            data-bs-toggle="dropdown"
            data-bs-auto-close="true"
          >
            {!user.AvatarUrl ? (
              <FaRegUser className="text-primary" />
            ) : (
              <img className="img-fluid" src={user.AvatarUrl} alt="avatar" />
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => navigate("/user/settings")}>
              <FaCog />
              {t("menu.settings")}
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={handleLogout}>
              <FaPowerOff />
              {t("menu.exit")}
            </Dropdown.Item>
            <div className="appVersion">v{versionInfo.version}</div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default Menu;
