const images = {
  arrowIcon: "/arrow_icon.png",
  iTrustArtIcon: "/ITrustArt_icon.png",
  iTrustArtIconB: "/ITrustArt_iconB.png",
  iTrustArtIconC: "/ITrustArt_iconC.png",
  iTrustArtLogo: "/ITrustArt_logo.png",
  qrIcon: "/QR_icon.png",
};

export default images;
