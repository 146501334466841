import { React, useState, useLayoutEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "./common/context";
import Menu from "./screens/Menu/Menu";
import Routing from "./Routing";
import Utils from "./common/util.jsx";

const Main = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [userIsActive, setUserIsActive] = useState(false);

  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  useLayoutEffect(() => {
    const _isUserActive = isUserActive();
    const pathname = window?.location?.pathname ?? "";

    setUserIsActive(_isUserActive);
    //If we have no user must go to login. We chek if it is login page to avoid redirect loop
    //'/setup/EmailValidation' can be opened as a standalone page, we do not need to login

    if (
      !_isUserActive &&
      !pathname.includes("/setup/EmailValidation") &&
      !pathname.includes("login") &&
      !pathname.includes("QRViewArtwork") &&
      !pathname.includes("TransferbyQR")
    ) {
      navigate("/login");
    }
  }, []);

  useLayoutEffect(() => {
    handleUrlChange();
  }, [location]);

  useLayoutEffect(() => {
    setUserIsActive(isUserActive());
  }, [JSON.stringify(user)]);

  //We consider a user active if we have the jwt stored.
  const isUserActive = () => Utils.isDefined(localStorage.getItem("token"));

  const handleUrlChange = () => {
    const pathname = window?.location?.pathname ?? "";
    const pagesWithoutMenu = [
      "login",
      "setting",
      "user/change-password",
      "user/profile",
      "user/change-language",
      "user/my-credits-balance",
      "/register",
      "/verifyEmail",
      "/verifyaccount",
      "/account/setup",
      "/resetpassword",
      "/verifyPhone",
      "/setup/EmailValidation",
      "/account/verification",
    ];
    const hideMenu = pagesWithoutMenu.some((p) => pathname.includes(p));
    const showOnQRViewArtwork =
      pathname.includes("QRViewArtwork") && userIsActive;
    setShowMenu(!hideMenu);
  };

  return (
    <div className="App">
      {showMenu && userIsActive && <Menu />}
      <div className="content">
        <Routing />
      </div>
    </div>
  );
};

export default Main;
