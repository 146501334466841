import React, { useState, lazy, Suspense, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Spinner from "./common/components/Spinner/Spinner";
import { useUser } from "./common/context";
import Utils from "./common/util";

const Register = lazy(() => import("./screens/Register/Register"));
const ResetPassword = lazy(() =>
  import("./screens/Login/ResetPassword/ResetPassword")
);
const Home = lazy(() => import("./screens/Home/Home"));
const ChangePassword = lazy(() =>
  import("./screens/User/ChangePassword/ChangePassword")
);
const Settings = lazy(() => import("./screens/User/Settings/Settings"));
const NewAsset = lazy(() => import("./screens/Assets/Asset/NewAsset"));
const Wallets = lazy(() => import("./screens/Wallets/Wallets"));
const Login = lazy(() => import("./screens/Login/Login"));
const AssetQR = lazy(() => import("./screens/Assets/Asset/AssetQR"));
const AssetQRPublic = lazy(() =>
  import("./screens/Assets/Asset/AssetQRPublic")
);
const AssetTransferVendor = lazy(() =>
  import("./screens/Assets/Asset/AssetTransfer")
);
const AssetTransferBuyerQR = lazy(() =>
  import("./screens/Assets/Asset/AssetTransferBuyerQR")
);
const Asset = lazy(() => import("./screens/Assets/Asset/Asset"));
const AccountSetUp = lazy(() => import("./screens/User/Account/AccountSetup"));
const Cart = lazy(() => import("./screens/Order/Cart"));
const OrderResult = lazy(() => import("./screens/Order/OrderResult"));
const ValidationProcess = lazy(() =>
  import("./screens/User/Account/ValidationProcess")
);
const EmailValidation = lazy(() =>
  import("./screens/EmailValidation/EmailValidation.jsx")
);
const PhoneValidation = lazy(() =>
  import("./screens/Register/VerifyTelephone.jsx")
);
const VerifyEmail = lazy(() => import("./screens/Register/VerifyEmail.jsx"));
const ITrustProfile = lazy(() =>
  import("./screens/User/iTrustProfile/ITrustProfile.js")
);
const ChangeLanguage = lazy(() =>
  import("./screens/User/ChangeLanguage/ChangeLanguage.js")
);
const MyCreditsBalance = lazy(() =>
  import("./screens/User/MyCreditsBalance/MyCreditsBalance.js")
);
const AuthorBrand = lazy(() =>
  import("./screens/User/iTrustProfile/AuthorBrand.js")
);

const Routing = (props) => {
  const [userIsActive, setUserIsActive] = useState(false);
  const { user } = useUser();

  useEffect(() => {
    setUserIsActive(Utils.isDefined(localStorage.getItem("token")));
  }, [JSON.stringify(user)]);

  return (
    <Suspense fallback={<Spinner showSpinner={true} />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/resetpassword/:id" element={<ResetPassword />} />
        <Route path="/register" element={<Register />} />

        {userIsActive && (
          <>
            <Route path="/user/settings" element={<Settings />} />
            <Route path="/user/change-password" element={<ChangePassword />} />
            <Route path="/user/change-language" element={<ChangeLanguage />} />
            <Route
              path="/user/my-credits-balance"
              element={<MyCreditsBalance />}
            />
            <Route path="/user/profile" element={<ITrustProfile />} />
            <Route path="/user/profile/brand" element={<AuthorBrand />} />
          </>
        )}

        {userIsActive &&
          user.IsAccountsetUp &&
          ((user.IsArtist && user.IsVerified) || !user.IsArtist) && (
            <>
              <Route path="/home" element={<Home key="home" />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/cart/result" element={<OrderResult />} />
              <Route path="/user/wallets" element={<Wallets />} />
              <Route exact path="/asset/:id" element={<Asset key="asset" />} />
              <Route
                exact
                path="/asset/qr/:id"
                element={<AssetQR key="asset" />}
              />
              <Route
                exact
                path="/asset/transfer/:id"
                element={<AssetTransferVendor key="asset" />}
              />
              <Route
                exact
                path="/TransferbyQR/:qrCode"
                element={<AssetTransferBuyerQR key="asset" />}
              />
            </>
          )}

        {userIsActive &&
          user.UserStatus == "Valid" &&
          user.IsAccountsetUp &&
          user.IsVerified &&
          user.IsArtist && (
            <>
              <Route path="/asset/new" element={<NewAsset />} />
              <Route path="/asset/edit/:id" element={<NewAsset />} />
            </>
          )}

        {userIsActive &&
          user.UserStatus == "Valid" &&
          user.IsAccountsetUp &&
          user.IsVerified &&
          !user.IsArtist && (
            <>
              <Route path="/asset/new" element={<Home />} />
              <Route path="/asset/edit/:id" element={<Home />} />
            </>
          )}

        {userIsActive && user.UserStatus == "Valid" && !user.IsAccountsetUp && (
          <>
            <Route path="/account/setup" element={<AccountSetUp />} />
            <Route
              path="/account/verification"
              element={<ValidationProcess />}
            />
          </>
        )}

        {userIsActive &&
          user.UserStatus == "Valid" &&
          user.IsAccountsetUp &&
          !user.IsVerified && (
            <Route
              path="/account/verification"
              element={<ValidationProcess />}
            />
          )}

        <Route path="/:lang?/verifyEmail" element={<VerifyEmail />} />
        <Route path="/:lang?/verifyPhone" element={<PhoneValidation />} />
        <Route
          path="/QRViewArtwork/:qrCode"
          element={<AssetQRPublic key="asset" />}
        />
        <Route
          exact
          path="/TransferbyQR/:qrCode"
          element={<AssetTransferBuyerQR key="asset" />}
        />
        <Route
          path="/:lang?/setup/EmailValidation/:code"
          element={<EmailValidation />}
        />
        <Route path="/*" element={<Home />} />
      </Routes>
    </Suspense>
  );
};

export default Routing;
