import { React, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ContextWrapper } from "./common/context";
import Analytics from "./Analytics";
import { useTranslation } from "react-i18next";
import Main from "./Main";
import { HelmetProvider } from "react-helmet-async";

const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    let languageCode = localStorage.getItem("language");
    if (!languageCode) {
      languageCode = "en";
    }
    i18n.changeLanguage(languageCode);
    localStorage.setItem("language", languageCode);
  }, [i18n]);

  return (
    <>
      <HelmetProvider>
        <BrowserRouter>
          <Analytics />
          <ContextWrapper>
            <Main />
          </ContextWrapper>
        </BrowserRouter>
        <ToastContainer
          preventDuplicates
          position="bottom-center"
          transitionIn="bounceIn"
          transitionOut="bounceOut"
        />
        <div
          id="newVersion"
          style={{
            display: "none",
            position: "fixed",
            bottom: "0",
            width: "100%",
            padding: "1em 1em",
            backgroundColor: "green",
            color: "white",
          }}
        >
          <div className="d-flex">
            <i
              className="fas fa-spinner fa-spin m-1"
              style={{ width: "1em", height: "1em" }}
            />
            <div className="ml-1 ">
              New version detected.
              <br />
              Please wait while app is reloading...
              <br />
              If the app does not reload,{" "}
              <a href="/home" style={{ color: "white" }}>
                click here
              </a>
            </div>
          </div>
        </div>
      </HelmetProvider>
    </>
  );
};

export default App;
