import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import swConfig from "./swConfig";
import i18n from "./i18nConfig";
import "./common/themes/theme.scss";
import MintNFTCostEstimation from "./cost";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
serviceWorkerRegistration.register(swConfig);
