import axios from "axios";
import Toastr from "../components/Toastr/Toastr";
import { refreshToken, logOut } from "./login-instance";

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    Accept: "application/json",
    "Cache-Control": "no-cache",
  },
  timeout: 30000,
});

const get = (endPoint, data = null, showGenericErrMsg = true) => {
  let _queryString = endPoint;
  if (!undefined(data)) {
    _queryString += objToQueryString(data);
  }
  return httpClient.get(_queryString, { ShowErrMsg: showGenericErrMsg });
};

const download = (endPoint, data = null, showGenericErrMsg = true) => {
  let _queryString = endPoint;
  if (!undefined(data)) {
    _queryString += objToQueryString(data);
  }
  return httpClient.get(_queryString, {
    responseType: "blob",
    ShowErrMsg: showGenericErrMsg,
  });
};

const post = (
  endPoint,
  data = null,
  isFileUpload = false,
  showGenericErrMsg = true,
  timeout = 30000
) => {
  const postHeaders = isFileUpload
    ? { "Content-Type": "multipart/form-data" }
    : {};
  return httpClient.post(endPoint, data, {
    headers: postHeaders,
    ShowErrMsg: showGenericErrMsg,
    timeout: timeout,
  });
};

export { get, post, httpClient };

//* Interceptors *//
//Request interceptor
httpClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    if (!undefined(token)) config.headers["Authorization"] = "Bearer " + token;

    return config;
  },
  (error) => {
    //catch the request error
    return Promise.reject(error);
  }
);

//Response interceptor
httpClient.interceptors.response.use(
  (response) => {
    return response; //Here it is possible to add logic for any successfull response
  },
  (error) => {
    const { config, response, message, request } = error;
    //Any error in the response will go trhough that function
    let originalRequest = { ...config };
    console.log("Error in response interceptor", error);
    if (response?.status === 401) {
      if (originalRequest?.url.includes("mobile/extendtoken")) {
        //Renew token has failed. Redirect to login

        logOut(); //TODO: use react router here?
        return Promise.reject(error);
      } else {
        if (!originalRequest.retry) {
          originalRequest.retry = true;
          return refreshToken(originalRequest);
        }
      }
    } else {
      //Unexpected error. Show message.
      if (originalRequest.showGenericErrMsg)
        Toastr.error("Oops! Something went wrong!");

      return Promise.reject(error);
    }
  }
);
//*End - Interceptors *//

//* Utils used by http service */
const undefined = (obj) =>
  obj === undefined ||
  obj === null ||
  obj === "" ||
  obj === "NULL" ||
  typeof obj === "undefined";

const objToQueryString = (obj, parentKey = "") => {
  //if the object is a string return without transformation
  if (typeof obj === "string") return obj;

  //Cannot convert array to qs by default
  if (Array.isArray(obj)) return "";

  if (typeof obj === "object") {
    let queryString = [];

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        const finalKey = parentKey ? `${parentKey}[${key}]` : key; //Ex: "person[name]=John%20Doe&person[age]=30&city=New%20York"

        if (!undefined(value)) {
          if (typeof value === "object" && !Array.isArray(value)) {
            // Recursively convert nested object
            queryString.push(objToQueryString(value, finalKey));
          } else {
            queryString.push(
              encodeURIComponent(finalKey) + "=" + encodeURIComponent(value)
            );
          }
        }
      }
    }
    return "?" + queryString.join("&");
  }

  return "";
};
//*End - Utils used by http service */
